<template>
  <div class="jumbotron-wrapper w-full h-full">
    <Jumbotron
      title="저렴하게 임대하자"
      description="노는공간에 임대료를 내 마음대로,<br/>내 생애 첫창업, 단돈 1만원이라도 아껴야죠!"
    />
    <slot></slot>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  name: "JumbotronLayout",
  components: {
    Jumbotron,
  },
};
</script>

<style lang="scss" scoped>
.jumbotron-wrapper {
  margin-top: 50px;
}
@media (max-width: 1023px) {
  .jumbotron-wrapper {
    margin-top: 28px;
  }
}
</style>
