<template>
  <Layout :title="'거래내역'">
    <div class="flex flex-col container mb-20">
      <TabLayout :tabs="tabs" :tabIndex="tabIndex" :onTab="onTab" />
      <div v-if="tabIndex === 0">
        <PlaceOfferItem
          v-for="(item, index) in data"
          :key="`place-${index}`"
          :data="item"
          :isReview="true"
          :onDetail="onDetailPlace"
        />
      </div>

      <div v-if="tabIndex === 1">
        <InteriorJoinOfferItem
          v-for="(item, index) in data"
          :isHistory="true"
          :key="`interior-${index}`"
          :data="item"
          :isReview="true"
          :onDetail="onDetailInterior"
        />
      </div>
    </div>

    <PlaceHistory :id="placeOfferId" :onRemove="onRemovePlace" />
    <InteriorHistory :id="interiorOfferId" :onRemove="onRemoveInterior" />
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import TabLayout from "@/components/TabLayout.vue";
import PlaceOfferItem from "@/components/PlaceOfferItem.vue";
import InteriorJoinOfferItem from "@/components/InteriorJoinOfferItem.vue";
import InteriorHistory from "@/components/InteriorHistory.vue";
import PlaceHistory from "@/components/PlaceHistory.vue";
import services from "@/services";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "History",
  components: {
    Layout,
    TabLayout,
    PlaceOfferItem,
    InteriorJoinOfferItem,
    InteriorHistory,
    PlaceHistory,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      tabs: ["공간거래", "비교견적"],
      tabIndex: 0,
      data: [],
      placeOfferId: -1,
      interiorOfferId: -1,
    };
  },
  methods: {
    onTab(value) {
      this.tabIndex = value;
      this.data = [];
      this.getData();
    },
    getData() {
      if (this.tabIndex === 0) {
        this.getPlaceJoinOffer();
      } else if (this.tabIndex === 1) {
        this.getInteriorOfferList();
      }
    },
    async getPlaceJoinOffer() {
      const [place, placeMe] = await Promise.all([
        services.placeJoinOfferList({ userId: this.userId, isConfirm: true }),
        services.placeJoinOfferList({
          placeOwnerId: this.userId,
          isConfirm: true,
        }),
      ]);
      this.data = _.concat(place, placeMe);
    },
    async getInteriorOfferList() {
      const [interior, interiorMe] = await Promise.all([
        services.interiorJoinOfferList({
          ownerId: this.userId,
          isConfirm: true,
        }),
        services.interiorJoinOfferList({
          interiorOfferUserId: this.userId,
          isConfirm: true,
        }),
      ]);
      this.data = _.concat(interior, interiorMe);
    },
    onDetailInterior(item) {
      this.interiorOfferId = item.id;
      this.$modal.show("interior-modal");
    },
    onDetailPlace(item) {
      this.placeOfferId = item.id;
      this.$modal.show("place-modal");
    },
    async onRemoveInterior(id) {
      const success = await services.interiorJoinOfferRemove(id);
      if (success) {
        this.$modal.hide("interior-modal");
        this.$toast.open({ message: "삭제완료" });
        this.getData();
      } else {
        this.$toast.open({ message: "삭제실패", type: "error" });
      }
    },
    async onRemovePlace(id) {
      const success = await services.placeJoinOfferRemove(id);
      if (success) {
        this.$modal.hide("place-modal");
        this.$toast.open({ message: "삭제완료" });
        this.getData();
      } else {
        this.$toast.open({ message: "삭제실패", type: "error" });
      }
    },
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
};
</script>

<style></style>
