<template>
  <modal
    name="interior-modal"
    :adaptive="true"
    :minWidth="786"
    :minHeight="740"
  >
    <div class="flex flex-col interior-history-wrapper">
      <div class="interior-info mb-10">
        <h1>
          {{ address }}
        </h1>
        <p>거래가 완료되었어요</p>
      </div>

      <div class="interior-history-section-3 flex flex-row justify-around">
        <div class="item">
          <div class="label flex flex-row items-center">
            <img src="@/assets/offer-1.png" />
            입찰 시작가
          </div>
          <div class="value w-full">{{ budget.toLocaleString() }}만원</div>
        </div>

        <div class="item">
          <div class="label flex flex-row items-center">
            <img src="@/assets/offer-3.png" />
            생성일
          </div>
          <div class="value w-full">{{ date }}</div>
        </div>
      </div>

      <div class="interior-history-section-2">
        <div class="item flex flex-row">
          <p class="label">입찰상태</p>
          <p class="value flex-1 active">거래완료</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">거래일시</p>
          <p class="value flex-1">{{ created }}</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">입찰제안가</p>
          <p class="value flex-1">{{ price.toLocaleString() }}만원</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">특이사항</p>
          <p class="value flex-1">{{ data.contents }}</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">최종 입찰가</p>
          <p class="value flex-1">{{ price.toLocaleString() }}만원</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">결제방식</p>
          <p class="value flex-1">계좌이체</p>
        </div>
      </div>

      <div class="flex flex-row justify-center items-center button-group">
        <button
          @click="onRemoveHistory"
          class="flex justify-center items-center delete"
        >
          거래내역 삭제
        </button>
        <button @click="onClose" class="flex justify-center items-center">
          창닫기
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import services from "@/services";
import dayjs from "dayjs";

export default {
  name: "InteriorHistory",
  props: {
    id: {
      type: Number,
      required: true,
    },
    onRemove: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      data: {},
      offers: [],
      contents: "",
    };
  },
  methods: {
    async getData() {
      if (this.id < 0) {
        return;
      }
      const data = await services.interiorJoinOfferDetail(this.id);
      this.data = data;
    },
    onRemoveHistory() {
      if (this.id > 0) {
        this.onRemove(this.id);
      }
    },
    onClose() {
      this.$modal.hide("interior-modal");
    },
  },
  computed: {
    address() {
      const { hasInteriorOffer } = this.data;
      if (hasInteriorOffer) {
        return `${hasInteriorOffer.address} ${hasInteriorOffer.addressDetail}`;
      }
      return "";
    },
    budget() {
      const { hasInteriorOffer } = this.data;
      if (hasInteriorOffer) {
        return hasInteriorOffer.budget / 10000;
      }
      return 0;
    },
    price() {
      const { price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    date() {
      const { hasInteriorOffer } = this.data;
      if (hasInteriorOffer) {
        return dayjs(hasInteriorOffer.createdAt).format("YYYY-MM-DD");
      }
      return "";
    },
    created() {
      const { createdAt } = this.data;
      if (createdAt) {
        return dayjs(createdAt).format("YYYY-MM-DD HH:MM");
      }
      return "";
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.interior-history-wrapper {
  padding: 30px 40px;

  button {
    height: 62px;
    border: solid 1px #ddd;
    background-color: #283aef;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .interior-info {
    h1 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.22px;
      text-align: left;
      color: #222;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.22px;
      text-align: left;
      color: #7ca3aa;
      margin-bottom: 20px;
    }
  }
}

.interior-history-section-1 {
  border: solid 1px #c9c9c9;
  margin-bottom: 20px;
}

.interior-history-section-2 {
  margin-bottom: 30px;
  background: "red";
  border-top: 1px solid #e6e6e6;

  .item {
    border-bottom: 1px solid #e6e6e6;
    height: 50px;

    .label {
      display: flex;
      flex-direction: "row";
      align-items: center;
      padding-left: 10px;
      background-color: #f7f7f7;
      width: 110px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c2c;
    }

    .value {
      display: flex;
      flex-direction: "row";
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #3d3d3d;

      &.active {
        color: #439f3f;
      }
    }
  }
}

.interior-history-section-3 {
  background-color: #f7f7f7;
  padding: 15px 30px;
  margin-bottom: 40px;

  .item {
    .label {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #838383;

      img {
        margin-right: 5px;
      }
    }

    .vaule {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: left;
      color: #333;
    }
  }
}

.interior-history-section-4 {
  margin-bottom: 15px;

  .price {
    width: 320px;
    margin-bottom: 15px;

    p {
      margin-left: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #d2d2d2;
    }
  }
}

.button-group {
  button {
    flex: 1;
    height: 57px;
    border-radius: 10px;
    background-color: #293bf0;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: left;
    color: #fff;

    &:not(:last-child) {
      margin-right: 35px;
    }

    &.delete {
      background-color: #e05050;
    }
  }
}

@media (max-width: 639px) {
  .interior-history-wrapper {
    padding: 10px 15px;
    .interior-info {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .interior-history-section-3 {
    padding: 10px 15px;
    flex-direction: column;
    gap: 15px;
    .item {
      .label {
        font-size: 12px;
      }
      .value {
        font-size: 12px;
      }
    }
  }
  .interior-history-section-2 {
    .item {
      .label {
        width: 80px;
        font-size: 12px;
      }
      .value {
        font-size: 12px;
      }
    }
  }
  .button-group {
    button {
      height: 35px;
      font-size: 13px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
</style>
