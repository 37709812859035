<template>
  <JumbotronLayout>
    <PageHeader :title="title" :link="link" :hasMenu="hasMenu" />

    <div class="w-full flex justify-center items-center">
      <slot></slot>
    </div>
  </JumbotronLayout>
</template>

<script>
import JumbotronLayout from '@/components/JumbotronLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Layout',
  components: {
    JumbotronLayout,
    PageHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    hasMenu: {
      type: Boolean,
      default: false,
    },
  },
}
</script>