<template>
  <div
    class="jumbotron-wrapper relative flex flex-col justify-center items-center"
    :style="{
      backgroundImage: 'url(' + require('@/assets/jumbotron.png') + ')',
    }"
  >
    <div class="dim" />
    <div class="flex flex-col justify-center items-center content">
      <p class="title">노는공간, <b>{{ title }}</b></p>
      <p class="description" v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jumbotron',
  props: ['title', "description"],
}
</script>

<style lang="scss" scoped>
  .jumbotron-wrapper {
    height: 362px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .dim {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      z-index: 1;
    }

    .content {
      z-index: 2;
    }

    .title {
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: center;
      color: #fefefe;

      b {
        font-weight: bold;
      }
    }

    .description {
      margin-top: 32px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.13;
      letter-spacing: -0.32px;
      text-align: center;
      color: #fefefe;
    }
  }

  @media (max-width: 639px) {
    .jumbotron-wrapper {
      height: 250px;
      .title {
        font-size: 23px;
      }
      .description {
        font-size: 13px;
      }
    }
  }
</style>